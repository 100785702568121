import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { AppContext } from '../components/layout';
import logoSAE from '../images/sae.svg';
import logoMiddlesex from '../images/middlesex.png';
import logoAudio from '../images/audio.svg';
import logoMusic from '../images/music.svg';
import logoFilm from '../images/film.svg';
import logoVFX from '../images/vfx.svg';
import logoGame from '../images/game.svg';
import logoWeb from '../images/web.svg';
import logoMPP from '../images/mpp.svg';
import animationDataInfo from '../images/btn-info';
import animationData360 from '../images/btn-360';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 30px 20px;
	
	& > .header {
		flex: 0 1 auto;
		display: flex;
		justify-content: center;
		margin-bottom: 40px;
		
		img {
			width: 24px;
			margin: 0 10px;
		}
	}
	
	& > .content {
		flex: 0 1 auto;
		text-align: center;
		
		img {
			width: 160px;
		}
		
		h2 {
			margin-bottom: 40px;
			font-size: 1.3rem;
			font-weight: 300;
		}
		
		h1 {
			margin-top: 40px;
			margin-bottom: 40px;
			font-size: 1.6rem;
		}
		
		p {
			font-size: 1.1rem;
		}
		
		.start {
			display: inline-block;
			width: 46%;
			max-width: 240px;
			padding: 15px 15px;
			margin: 0 5px 20px 5px;
			cursor: pointer;
			font-size: 0.9rem;
			background: rgba(255, 255, 255, 0.05);
			transition: background 0.3s ease-in-out;
			
			h4 {
				margin-top: 5px;
				margin-bottom: 0;
			}
			
			.icon {
				display: inline-block;
				position: relative;
				width: 64px; 
				height: 36.95px;
				background-color: #00A7B5;
				margin: 18.48px 0;
				transition: transform 0.6s ease-in-out;
				
				&:before, &:after {
					content: "";
					position: absolute;
					width: 0;
					border-left: 32px solid transparent;
					border-right: 32px solid transparent;
				}
				
				&:before {
					bottom: 100%;
					left: 0;
					border-bottom: 18.48px solid #00A7B5;
				}
					
				&:after {
					top: 100%;
					left: 0;
					width: 0;
					border-top: 18.48px solid #00A7B5;
				}
			}
			
			&:hover, &:active {
				background: rgba(255, 255, 255, 0.08);
				
				.icon {
					transform: rotateY(360deg);
				}
			}
		}
	}
	
	& > .footer {
		flex: 0 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 40px;
		
		h3 {
			margin: 0;
			font-weight: 300;
			text-align: center;
			font-size: 1rem;
		}
		
		div {
			margin-bottom: 20px;
		}
	}
	
	@media (min-width: 768px) {
		padding: 40px;
		
		& > .header {
			img {
				width: 32px;
				margin: 0 20px;
			}
		}
		
		& > .content {
			max-width: 80vw;
			
			img {
				width: 240px;
			}
			
			h2 {
				margin-top: 0;
				margin-bottom: 40px;
				font-size: 1.6rem;
			}
			
			h1 {
				margin-top: 50px;
				margin-bottom: 60px;
				font-size: 2rem;
			}
			
			p {
				margin: 30px 0;
			}
		}
		
		& > .footer {
			flex-direction: row;
			align-items: flex-end;
			padding-bottom: 30px;
			
			h3 {
				text-align: left;
				font-size: 1.2rem;
			}
			
			div {
				margin-bottom: 0;
			}
		}
	}
`;

const RegisterBtn = () => {
	const ref = useRef(null);

	const spawnBtn = () => {
		if (window.lottie) {
			const btn = document.createElement('div');
			ref.current.appendChild(btn);

			const elemLottie = window.lottie.loadAnimation({
				container: btn,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: animationDataInfo
			});
			elemLottie.setSpeed(2);

			ref.current.addEventListener('mouseenter', () => {
				elemLottie.goToAndPlay(0, true);
			});
		}
		else {
			setTimeout(() => {
				spawnBtn();
			}, 100);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			spawnBtn();
		}, 100);
	}, []);

	return <div ref={ref} />
};

const StartBtn = () => {
	const ref = useRef(null);

	const spawnBtn = () => {
		if (window.lottie) {
			const btn = document.createElement('div');
			ref.current.appendChild(btn);

			const elemLottie = window.lottie.loadAnimation({
				container: btn,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: animationData360
			});
			elemLottie.setSpeed(2);

			ref.current.addEventListener('mouseenter', () => {
				elemLottie.goToAndPlay(0, true);
			});
		}
		else {
			setTimeout(() => {
				spawnBtn();
			}, 100);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			spawnBtn();
		}, 100);
	}, []);

	return <div ref={ref} />
};

const IndexPage = () => {
	const { setSchedulesModal } = useContext(AppContext);

	useEffect(() => {
			(function(){
				var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
				s1.async=true;
				s1.src='https://embed.tawk.to/5ebd24ca8ee2956d73a10e1d/default';
				s1.charset='UTF-8';
				s1.setAttribute('crossorigin','*');
				s1.onload = function() {
					setTimeout(() => {
						if (document.querySelector('body > div[id]:not(#___gatsby)')) {
							document.querySelector('body > div[id]:not(#___gatsby)').setAttribute('style', 'display: block !important; position: absolute !important; top: 60px !important; right: 10px !important; transform: translateY(10vh) !important;');
						}
					}, 100);
					setTimeout(() => {
						if (document.querySelector('body > div[id]:not(#___gatsby)')) {
							document.querySelector('body > div[id]:not(#___gatsby)').setAttribute('style', 'display: block !important; position: absolute !important; top: 60px !important; right: 10px !important; transform: translateY(10vh) !important;');
						}
					}, 500);
					setTimeout(() => {
						if (document.querySelector('body > div[id]:not(#___gatsby)')) {
							document.querySelector('body > div[id]:not(#___gatsby)').setAttribute('style', 'display: block !important; position: absolute !important; top: 60px !important; right: 10px !important; transform: translateY(10vh) !important;');
						}
					}, 1000);
					setTimeout(() => {
						if (document.querySelector('body > div[id]:not(#___gatsby)')) {
							document.querySelector('body > div[id]:not(#___gatsby)').setAttribute('style', 'display: block !important; position: absolute !important; top: 60px !important; right: 10px !important; transform: translateY(10vh) !important;');
						}
					}, 2000);
				};
				s0.parentNode.insertBefore(s1,s0);
			})();
	}, []);

	return (
		<>
			<SEO title="Home"/>

			<Content>
				<div className="header">
					<img src={logoAudio} alt="Audio Production" />
					<img src={logoMusic} alt="Music Business" />
					<img src={logoFilm} alt="Digital Film Production" />
					<img src={logoVFX} alt="Visual Effects Animation" />
					<img src={logoGame} alt="Game Art Animation" />
					<img src={logoWeb} alt="Games Programming" />
					<img src={logoMPP} alt="Media Prod. and Publishing" />
				</div>

				<div className="content">
					<h2>GLOBAL LEADER IN CREATIVE MEDIA EDUCATION</h2>

					<img src={logoSAE} alt="SAE Institute Genève" />

					<h1>JOURNÉE PORTES OUVERTES ONLINE</h1>

					<div>
						<div className="start" onClick={() => setSchedulesModal(true)}>
							<RegisterBtn />
							<h4>PRÉSENTATIONS EN DIRECT</h4>
						</div>

						<Link to="/360" className="start">
							<StartBtn />
							<h4>VISITE 360° DE L'ÉCOLE</h4>
						</Link>
					</div>
				</div>

				<div className="footer">
					<div>
						<h3>MASTER / BACHELOR OF ARTS / SCIENCE (Honours)</h3>
					</div>

					<div>
						<img src={logoMiddlesex} alt="In partnership with Middlesex University" />
					</div>
				</div>
			</Content>
		</>
	);
};

export default () => (
	<Layout>
		<IndexPage />
	</Layout>
);
